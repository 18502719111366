
























































































































































































































































































































































































































































import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  VBTooltip,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";

// Services
import {
  useDealerships,
  useUser,
  useRegion,
  useAdministrator,
  usePerson,
} from "@/services";

// Interfaces
import { IOption } from "@core/services/interfaces/IUtil";
import {
  IResponseGetById,
  IFieldPermissions,
  IResponseGetDailyReportTypes,
  IResponseList,
} from "@core/services/interfaces/user/IUserService";
import {
  IResponseGetFiltersDealerships,
  IEconomicGroupFilter,
  IBusinessGroupFilter,
  IDealershipFilter,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import {
  IRegionApi,
  IResponseGetRegionActive,
} from "@core/services/interfaces/covarege-area/region/IRegionService";
import {
  IBodyUpdateUser,
  IResponseGetProfiles,
} from "@core/services/interfaces/administrator/IAdministratorService";

interface IUserLocal extends IBodyUpdateUser {
  gruposEconomicos: number[];
  gruposEmpresas: number[];
  tipoBoletimDiario: number;
  perfilId: string;
  concessionariasIds: string[];
  regioesOperacionaisIds: string[];
  email: string;
}

interface IBusinessGroupsOptions extends IOption {
  economicGroupId: number | null;
}

interface IDealershipsOptions extends IOption {
  groupId: number | null;
}

@Component({
  name: "UsersTabInfo",
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    AvButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class UsersTabInfo extends Vue {
  // Data
  $refs = {
    refFormObserver: {} as any,
  };
  groupTitle =
    this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE ||
    "Grupo Empresa";
  $ENUMS = ENUMS;
  saving = false;
  isUserAdmin: boolean =
    JSON.parse(localStorage.getItem("userData") || "").ehAdmin || false;
  show: IFieldPermissions = {} as IFieldPermissions;
  required: IFieldPermissions = {} as IFieldPermissions;
  validCpf = false;
  userLocal: IUserLocal = {} as IUserLocal;
  profileOptions: IOption[] = [];
  groupsEconomicsOptions: IOption[] = [];
  businessGroups: IBusinessGroupFilter[] = [];
  businessGroupsOptions: IBusinessGroupsOptions[] = [];
  dealerships: IDealershipFilter[] = [];
  dealershipsOptions: IDealershipsOptions[] = [];
  operationalRegionOptions: IOption[] = [];
  dailyReportTypesOptions: IOption[] = [];
  loadingUser = false;
  loadingProfile = false;
  loadingRegion = false;
  loadingEconomicGroup = false;
  loadingGroup = false;
  loadingDealership = false;
  hasEconomicGroup = true;
  validatingCpf = false;
  loadingdailyReportTypes = false;
  currentEmail = "";
  validatingEmail = false;
  validEmail = true;

  formValidation = formValidation();

  // Computeds
  get isValidCpf(): boolean | null {
    if (typeof this.userLocal.documento == "undefined") {
      return null;
    }

    return this.userLocal.documento != "" ? this.validCpf : null;
  }

  get isValidEmail(): boolean | null {
    if (typeof this.userLocal.email == "undefined") {
      return null;
    }

    return this.userLocal.email != "" ? this.validEmail : null;
  }

  // LifeCycle
  created() {
    this.fetchGetByIdUser();
    this.fetchListProfiles();
    this.fetchGetFilters();
    this.fetchGetDailyReportTypes();
    this.hasEconomicGroup =
      this.$store.state["appConfig"].params.EMP_ASSOCIACAO_GRUPO_ECONOMICO ==
      "S"
        ? true
        : false;

    if (this.isUserAdmin) {
      this.fetchGetOperationalRegions();
    }
  }

  // Watchs
  @Watch("userLocal.perfilId")
  getPermissions() {
    this.show = useUser.getVisualizationPermissions(
      this.userLocal.perfilId || ""
    );
    this.required = useUser.getRequiredPermissions(
      this.userLocal.perfilId || ""
    );
  }

  @Watch("businessGroups")
  updatebusinessGroupsOptions() {
    this.businessGroupsOptions = this.businessGroups.map((group) => ({
      label: group.Nome,
      value: group.Id,
      economicGroupId: group.GrupoEconomicoId,
    }));
  }

  @Watch("dealerships")
  updateDealershipsOptions() {
    this.dealershipsOptions = this.dealerships.map(
      (dealerships: IDealershipFilter) => ({
        value: dealerships.Id,
        label: dealerships.NomeFantasia,
        groupId: dealerships.GrupoEmpresaId,
      })
    );
  }

  watchGrupoEconomicoId() {
    // Verificando se os valores selecionados em grupo empresa estão contidos nas novas opções de grupo empresa
    const groups = this.businessGroups
      .filter((group) =>
        group.GrupoEconomicoId
          ? this.userLocal.gruposEconomicos.includes(group.GrupoEconomicoId)
          : false
      )
      .map((group) => group.Id);

    this.userLocal.gruposEmpresas = this.userLocal.gruposEmpresas.filter((id) =>
      groups.includes(id)
    );

    // Limpando as options de grupo empresa de acordo com os grupos econômicos selecionados
    if (this.userLocal.gruposEconomicos.length > 0) {
      this.businessGroupsOptions = this.businessGroups
        .filter((group) =>
          group.GrupoEconomicoId
            ? this.userLocal.gruposEconomicos.includes(group.GrupoEconomicoId)
            : false
        )
        .map((group) => ({
          label: group.Nome,
          value: group.Id,
          economicGroupId: group.GrupoEconomicoId,
        }));
    } else {
      this.businessGroupsOptions = this.businessGroups.map((group) => ({
        label: group.Nome,
        value: group.Id,
        economicGroupId: group.GrupoEconomicoId,
      }));
    }

    this.watchGrupoEmpresaId();
  }

  watchGrupoEmpresaId() {
    // Verificando se os valores selecionados em concessionárias estão contidos nas novas opções de concessionárias
    const dealershipsIds = this.dealershipsOptions
      .filter((dealership) =>
        dealership.groupId
          ? this.userLocal.gruposEmpresas.includes(dealership.groupId)
          : false
      )
      .map((dealership) => dealership.value);

    if (this.userLocal.concessionariasIds) {
      this.userLocal.concessionariasIds =
        this.userLocal.concessionariasIds.filter((dealershipId) =>
          dealershipsIds.includes(dealershipId)
        );
    }

    // Limpando as options de concessionárias de acordo com os grupos empresas selecionados
    if (this.userLocal.gruposEmpresas.length > 0) {
      this.dealershipsOptions = this.dealerships
        .filter((dealership) =>
          dealership.GrupoEmpresaId
            ? this.userLocal.gruposEmpresas.includes(dealership.GrupoEmpresaId)
            : false
        )
        .map((dealerships: IDealershipFilter) => ({
          value: dealerships.Id,
          label: dealerships.NomeFantasia,
          groupId: dealerships.GrupoEmpresaId,
        }));
    } else {
      const groups = this.businessGroupsOptions.map((group) => group.value);
      this.dealershipsOptions = this.dealerships
        .filter((dealership) =>
          dealership.GrupoEmpresaId
            ? groups.includes(dealership.GrupoEmpresaId)
            : false
        )
        .map((dealerships: IDealershipFilter) => ({
          value: dealerships.Id,
          label: dealerships.NomeFantasia,
          groupId: dealerships.GrupoEmpresaId,
        }));
    }
  }

  // Methods
  fetchGetByIdUser(): void {
    this.loadingUser = true;
    useUser
      .requestGetById(this.$route.params.id)
      .then((response: IResponseGetById) => {
        const data = response.data.Data[0];
        this.currentEmail = data.Email;

        this.userLocal = {
          id: data.Id,
          perfilId: data.PerfilId,
          nome: data.Nome,
          documento: data.CpfCnpj,
          email: data.Email,
          telefone: data.Telefone || "",
          status: data.Ativo,
          tipoBoletimDiario: data.TipoBoletimDiario,
          concessionariasIds: data.ConcessionariasIds,
          regioesOperacionaisIds: data.RegioesOperacionaisIds,
          gruposEconomicos: [],
          gruposEmpresas: [],
          perfisIds: [data.PerfilId],
        };

        if (this.userLocal.documento) {
          this.cpfExist(this.userLocal.documento);
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os dados do usuário!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.userLocal = {} as IUserLocal;
      })
      .finally(() => {
        this.loadingUser = false;
      });
  }

  fetchListProfiles(): void {
    this.loadingProfile = true;
    useAdministrator
      .requestGetProfiles()
      .then((response: IResponseGetProfiles) => {

        const data: Array<{ value: string; label: string }> =
          response.data.data
          .filter(profile => profile.status)
          .map((profile) => ({
            value: profile.id,
            label: profile.nome,
          }));

        this.profileOptions = data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Perfis!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.profileOptions = [];
      })
      .finally(() => {
        this.loadingProfile = false;
      });
  }

  fetchGetFilters(): void {
    this.loadingEconomicGroup = true;
    this.loadingGroup = true;
    this.loadingDealership = true;
    useDealerships
      .requestGetFilters()
      .then((response: IResponseGetFiltersDealerships) => {
        this.groupsEconomicsOptions = response.data.Data.GruposEconomicos.map(
          (groupEconomic: IEconomicGroupFilter) => ({
            value: groupEconomic.Id,
            label: groupEconomic.Nome,
          })
        );

        this.businessGroups = response.data.Data.GruposEmpresa;
        this.dealerships = response.data.Data.Empresas;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os filtros da página!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingEconomicGroup = false;
        this.loadingGroup = false;
        this.loadingDealership = false;
      });
  }

  fetchGetDailyReportTypes() {
    this.loadingdailyReportTypes = true;
    useUser
      .requestGetDailyReportTypes()
      .then((response: IResponseGetDailyReportTypes) => {
        this.dailyReportTypesOptions = response.data.Data.map((item) => ({
          label: item.Value,
          value: item.Key,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os tipos de boletim diário",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingdailyReportTypes = false;
      });
  }

  fetchGetOperationalRegions(): void {
    this.loadingRegion = true;
    useRegion
      .requestGetActives()
      .then((response: IResponseGetRegionActive) => {
        this.operationalRegionOptions = response.data.Data.map(
          (groupEconomic: IRegionApi) => ({
            value: groupEconomic.Id || null,
            label: groupEconomic.Nome,
          })
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Regiões Operacionais!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.profileOptions = [];
      })
      .finally(() => {
        this.loadingRegion = false;
      });
  }

  onSubmit(): void {
    this.$refs.refFormObserver
      .validate()
      .then((success: boolean) => {
        if (success) {
          this.saving = true;

          if (this.userLocal.documento) {
            this.userLocal.documento = this.userLocal.documento.replace(
              /\D/g,
              ""
            );
          }

          const bodyUpdateUser: IBodyUpdateUser = {
            id: this.userLocal.id,
            documento: this.userLocal.documento,
            nome: this.userLocal.nome,
            telefone: this.userLocal.telefone,
            perfisIds: [this.userLocal.perfilId],
            status: this.userLocal.status,
            dadosAdicionais: {
              TipoBoletimDiario: this.userLocal.tipoBoletimDiario,
              ConcessionariasIds: this.userLocal.concessionariasIds,
              RegioesOperacionaisIds: this.userLocal.regioesOperacionaisIds,
              PerfisIds: [this.userLocal.perfilId],
              GrupoId: JSON.parse(localStorage.getItem("userData") || "").groupId,
            },
          };

          useAdministrator
            .requestUpdateUser(bodyUpdateUser)
            .then(() => {
              if (this.currentEmail != this.userLocal.email) {
                useAdministrator
                  .requestUpdateLoginUser(
                    bodyUpdateUser.id,
                    this.userLocal.email
                  )
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Usuário atualizado com sucesso!",
                        icon: "CheckIcon",
                        variant: "success",
                      },
                    });
                  })
                  .catch(() => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Atenção",
                        text: "Usuário foi atualizado, no entanto, não foi possível atualizar o e-mail",
                        icon: "AlertTriangleIcon",
                        variant: "warning",
                      },
                    });
                  })
                  .finally(() => {
                    this.saving = false;
                  });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Usuário atualizado com sucesso!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                this.saving = false;
              }
            })
            .catch((data: XMLHttpRequest) => {
              let title = "Erro ao tentar atualizar o usuário!";
              if (Array.isArray(data.response.data.errors)) {
                if (data.response.data.errors.length > 0)
                  title = data.response.data.errors[0];
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: title,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });

              this.saving = false;
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Preencha todos os campos obrigatórios!",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          if (document.querySelectorAll(".is-invalid").length) {
            const y =
              document
                .querySelectorAll(".is-invalid")[0]
                .getBoundingClientRect().top +
              window.scrollY -
              125;
            window.scroll({
              top: y,
              behavior: "smooth",
            });
          }
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Ocorreu um erro inesperado! Acione a administração do sistema",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  reduceValue = (option: { label: string; value: any }): any => {
    return option.value;
  };

  checkStateCpf(validationContext: any): boolean | null {
    if (this.validatingCpf) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidCpf === null) {
      return null;
    } else if (this.isValidCpf === null) {
      return formValidation;
    } else {
      return this.isValidCpf;
    }
  }

  clearCaracter(value: string) {
    this.userLocal.documento = value.replace(/\D/g, "");
  }

  /**
   * Essa função adiciona ou remove todas as opções do select de concessionárias
   */
  toggleDealerships(value: boolean) {
    if (value) {
      this.userLocal.concessionariasIds = this.dealershipsOptions.map(
        (item) => item.value
      );
    } else {
      this.userLocal.concessionariasIds = [];
    }
  }

  cpfExist(cpf: string) {
    const regexApenasNumeros = /[^\d]/g;
    const cpfSanatize = (cpf || "").replace(regexApenasNumeros, "");

    if (cpfSanatize.length == 11) {
      this.validatingCpf = true;

      usePerson
        .requestValidCpfCnpj(cpf)
        .then(() => {
          this.validCpf = true;
        })
        .catch(() => {
          this.validCpf = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "CPF inválido!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.validatingCpf = false;
        });
    } else {
      this.validCpf = false;
    }
  }

  checkStateEmail(validationContext: any): boolean | null {
    if (this.validatingEmail) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidEmail === null) {
      return null;
    } else if (this.isValidEmail === null) {
      return formValidation;
    } else {
      return this.isValidEmail;
    }
  }

  checkEmail(isEmailValid: boolean | null) {
    if (isEmailValid) {
      // Checando se o e-mail já existe
      this.validatingEmail = true;
      useUser
        .requestList({
          draw: 1,
          length: 10,
          userdata: {
            email: this.userLocal.email,
          },
        })
        .then((response: IResponseList) => {
          const index = response.data.data.findIndex(
            (it) => it.Email == this.userLocal.email
          );

          if (index >= 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "E-mail existente",
                text: "Já existe um usuário cadastrado com o e-mail informado",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });

            this.validEmail = false;
          } else {
            this.validEmail = true;
          }
        })
        .finally(() => {
          this.validatingEmail = false;
        });
    }
  }
}
